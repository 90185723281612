'use strict'
import hammer from 'hammerjs'
import {elemPosPerc} from "../elemPos.js";

const slider = $('#home-slider');
const preloader = slider.find('#slider-preloader');
const sliderDotNav = $('.fs-slider__dots');
const startFromSlide = 0;
const minDarkLevel = 100;
const changeInterval = 10000;
let countSlides = 0;

//var for autochange and video state check
let autochangeTimeout;
let preloadVideoCheckInterval;

let getNextSlide = () => {
    const as = slider.find('.slide.active').index();
    getSlide(as + 1);
}

let getPrevSlide = () => {
    const as = slider.find('.slide.active').index();
    getSlide(as - 1);
}

let getSlide = (sNum) => {
    clearTimeout(autochangeTimeout)

    // detect if needed slide exists
    if(sNum + 1 > countSlides){
        sNum = 0;
    }
    if(sNum < 0){
        sNum = countSlides - 1;
    }

    var slide = $('.fs-slider__slides').find('.slide').eq(sNum);

    if(slide.hasClass('preloaded')){
        if(slide.hasClass('video-slide')){
            activateLoadedSlide(slide);
        }
        else{
            activateLoadedSlide(slide);
        }
    }
    else{
        if(slide.hasClass('video-slide')){
            preloadVideoSlide(slide);
        }
        else{
            preloadPhotoSlide(slide, true);
        }
    }
}


let preloadSlider = () => {
    getSlide(startFromSlide);
    preloadAllSlides();
}

let preloadAllSlides = () => {
    if(countSlides > 1){
        slider.find('.slide').each(function(){
            const slide = $(this);
            const slideId = slide.index();
            addDotToNav(slideId);
            if(slideId > 0){
                if(slide.hasClass('video-slide')){
                    const video = slide.find('video');
                    if(video.get(0).readyState >= 4){
                        slide.addClass('preloaded');
                    }
                }
                else{
                    preloadPhotoSlide(slide, false)
                }
                if(slideId + 1 == countSlides){
                    sliderDotNav.addClass('isvis');
                    initSliderHammer();
                }
            }
        })
    }
}

let preloadPhotoSlide = (slide, showSlide) => {
    const imageUrl = slide.attr("data-bg-image").replace('url(','').replace(')','').replace(/\"/gi, "");
    let image = new Image();
    image.src = imageUrl;
    image.onload = () => {
        slide.find('.slide__img').prop('src', imageUrl);
        setSliderStyle(slide);
        slide.addClass('preloaded');
        if(showSlide === true){
            activateLoadedSlide(slide);
        }
    }
}



let preloadVideoSlide = (slide) => {
    clearInterval(preloadVideoCheckInterval);
    showPreloader();
    const video = slide.find('video');
    
    if(!video.hasClass('metadataloaded') && !video.hasClass('waitformetadata')){
        video.addClass('waitformetadata');
        video.on('loadedmetadata ', function(){
            video.addClass('metadataloaded');
        })
    };

    if (video.get(0).readyState >= 4 || video.hasClass('metadataloaded')) {
        video.removeClass('waitformetadata');
        video.closest('.slide').addClass('preloaded');
        setSliderStyle(slide);
        activateLoadedSlide(slide);
    }
    else{
        preloadVideoCheckInterval = setInterval(()=>{
            preloadVideoSlide(slide);
        },500);
    }
}

let activateLoadedSlide = (slide) => {
    deactivateSlides();
    sliderDotNav.find('li').eq(slide.index()).addClass('active');
    slide.addClass('active');
    if(slide.hasClass('video-slide')){
        clearTimeout(autochangeTimeout);
        var playPromise = slide.find('video').get(0).play();
        if (playPromise !== undefined) {
            playPromise.then(function() {
            }).catch(function(error) {
                console.log(error)
            });
          }
    }
    else{
        autochangeTimeout = setTimeout(() => {
            getNextSlide();
        }, changeInterval)
    }
    setGenralStyle(slide);
    hidePreloader();
}

let deactivateSlides = () => {
    slider.find('video-slide').find('video').each(function(){
        $(this).get(0).pause();
    })
    slider.find('.slide').removeClass('active');
    sliderDotNav.find('li').removeClass('active');
}


let setSliderStyle = (slide) => {
    const imageBrightness = parseInt(slide.attr("data-bg-brightness"));
    if(imageBrightness <= minDarkLevel || slide.hasClass('dark-slide')){
        slide.addClass('white-on-black');
    }
    else{
        slide.removeClass('white-on-black');
    }
};

let setGenralStyle = (slide) => {
    const imageBrightness = parseInt(slide.attr("data-bg-brightness"));
    if(imageBrightness && imageBrightness <= minDarkLevel || slide.hasClass('dark-slide')){
        $('header').addClass('white-on-black');
        slider.addClass('white-on-black');
    }
    else{
        $('header').removeClass('white-on-black');
        slider.removeClass('white-on-black');
    }
};

let addDotToNav = (id) => {
    let cls = '';
    if(id == startFromSlide){
        cls='active'
    }
    sliderDotNav.append('<li class="'+cls+'"><a href="#"></a></li>');
}

let hidePreloader = () => {
    preloader.addClass('disabled');
}

let showPreloader = () => {
    preloader.removeClass('disabled');
}



//on video end
$('video:not(.event-video)').on('ended', function(){
    let delay = 0;
    if(Number.isInteger(parseInt($(this).closest('.slide').attr('data-attr-delayafter')))){
        delay = parseInt($(this).closest('.slide').attr('data-attr-delayafter')) * 1000;    
    }
    autochangeTimeout = setTimeout(() => {
        getNextSlide();
    }, delay);
})

//pause video on tab change etc.
document.addEventListener("visibilitychange", onchange);
let onchange = (evt) => {
    let activeSlide = slider.find('.slide.active');
    if(activeSlide.hasClass('video-slide')){
        if(!activeSlide.find('video').hasClass('paused')){
            activeSlide.find('video').addClass('paused').get(0).pause();
        }
        else{
            if(activeSlide.find('video').hasClass('paused')){
                el.find('video').removeClass('paused').get(0).play();
            }
        }
    }
}

function initSliderHammer() {
    var hElement = slider.get(0);
    var hammertime = new Hammer(hElement);
    hammertime.on('swipeleft', function () {
        getNextSlide();
    });
    hammertime.on('swiperight', function () {
        getPrevSlide();
    });
}


//EVENTS
$(document).ready(function () {
    countSlides = $('.fs-slider__slides').find('.slide').length;
    countSlides > 0 ? preloadSlider() : hidePreloader();
})

$(document).on('click', '.fs-slider__dots li a', function (e) {
    e.preventDefault();
    if (!$(this).closest('li').hasClass('active')) {
        var sNum = $(this).closest('li').index();
        getSlide(sNum)
    } else {
        return;
    }
})

$(document).on('click', '.slide-volume', function (e){
    e.preventDefault();
    const slide = $(this).closest('.video-slide');
    const video = slide.find('video');
    const muteIcon = $(this);
    if(muteIcon.hasClass('muted')){
        video.get(0).muted = false;
    }
    else{
        video.get(0).muted = true;
    }
    muteIcon.toggleClass('muted');
})

$(window).scroll(function () {
    if ($('.fs-slider').length) {
        const perc = elemPosPerc($('#home-slider'), 100, 'top', true)
        const scale = 1 + 0.2 * perc;
        $('.fs-slider').find('.slide.active .slide__img').css('transform', 'scale(' + scale + ')');
    }
})